import { useEffect, useState } from 'react';
import PublicNavbar from '../../components/PublicNavBar';
import { Outlet } from 'react-router-dom';
import './PublicLayout.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import userApi from '../../api/user.api';
import userAuthStore from '../../stores/auth.store';
import { useParams } from 'react-router-dom';

export default function PublicLayout() {
	const [ready, setReady] = useState(false);
	const { userId, jobfithash } = useParams();
	// console.log('userId', userId);

	const fetchUserProfile = async () => {
		// set user profile
		try {
			const response = await userApi.getCurrentWebUser();
			userAuthStore.setState({ displayName: response.data.displayName });
			userAuthStore.setState({ userId: response.data.id });
			// console.log('finished........');
		} catch (err) {
		} finally {
			setReady(true);
		}
	};

	useEffect(() => {
		// console.log('PublicLayout.useEffect()');
		localStorage.setItem('user-id', userId);
		fetchUserProfile();
	}, []);

	if (ready) {
		return (
			<div className="flex flex-col h-full">
				<PublicNavbar></PublicNavbar>
				<div className="public-container grow shrink basis-auto">
					<Outlet />
				</div>
			</div>
		);
	} else {
		return (
			<Box
				sx={{ display: 'flex' }}
				className="w-full flex justify-center min-h-screen items-center"
			>
				<CircularProgress />
			</Box>
		);
	}
}
