import React, { useState, useEffect, useMemo } from 'react';
import dashboardShareApi from 'src/api/Dashboard.share.api';
import formStore from 'src/stores/form.store';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import { Typography, Box, Grid, Tabs, Tab, Paper } from '@mui/material';
import ResumeComponent from './Resume';
import CoverLetterComponent from './CoverLetter';
import JobPost from './JobPost';
import JobDescriptionComponent from './JobDescription';
import JobDashboardComponent from './JobDashboard';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';

function TabPanel(props) {
	const { children, value, currentValue, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={currentValue !== value}
			id={`simple-tabpanel-${value}`}
			aria-labelledby={`simple-tab-${value}`}
			{...other}
		>
			{value === value && <>{children}</>}
		</div>
	);
}

function JobFitInsight({ isPublic }) {
	const [ready, setReady] = useState(true);
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const [userInfoTab, setUserInfoTab] = useState('coverLetter');
	const [jobInfoTab, setJobInfoTab] = useState('jobDescription');

	const { jobfithash } = useParams();

	const handleUserTabChange = (event, newValue) => {
		setUserInfoTab(newValue);
	};

	const handleJobTabChange = (event, newValue) => {
		setJobInfoTab(newValue);
	};

	const { data, loading } = useRequest(() =>
		dashboardShareApi.getShareHashData(jobfithash)
	);

	const jobData = useMemo(() => data?.data || {}, [data]);

	const {
		data: _resumeData,
		loading: loadingResume,
		run: getResumeData,
	} = useRequest(
		() => dashboardShareApi.getShareHashDataResume(jobData?.jobId, jobfithash),
		{
			ready: !!jobData?.jobId,
			manual: true,
		}
	);

	const resumeData = useMemo(() => _resumeData?.data || {}, [_resumeData]);

	const {
		data: _jobDetail,
		loading: loadingJobDetail,
		run: getJobDetail,
	} = useRequest(
		() =>
			dashboardShareApi.getShareHashDataJobDetail(jobData?.jobId, jobfithash),
		{
			ready: !!jobData?.jobId,
			manual: true,
		}
	);

	const jobDetail = useMemo(() => _jobDetail?.data || {}, [_jobDetail]);

	const {
		data: _coverLetterData,
		loading: loadingCoverLetter,
		run: getCoverLetterData,
	} = useRequest(
		() =>
			dashboardShareApi.getShareHashDataCoverletter(
				jobData?.coverLetterId,
				jobfithash
			),
		{
			ready: !!jobData?.jobId,
			manual: true,
		}
	);

	const coverLetterData = useMemo(
		() => _coverLetterData?.data || {},
		[_coverLetterData]
	);

	// const { data: _trackingTotalData, loading: loadingTrackingTotal } =
	// 	useRequest(() =>
	// 		dashboardShareApi.getShareHashDataTrackingTotal(jobfithash)
	// 	);
	// const trackingTotalData = useMemo(
	// 	() => _trackingTotalData?.data || {},
	// 	[_trackingTotalData]
	// );

	// const { data: _trackingJobStatus, loading: loadingTrackingJobStatus } =
	// 	useRequest(() => dashboardShareApi.getShareHashDataJobStatus(jobfithash));
	// const trackingJobStatus = useMemo(
	// 	() => _trackingJobStatus?.data || [],
	// 	[_trackingJobStatus]
	// );

	// const { data: _trackingSavedTitles, loading: loadingTrackingSavedTitles } =
	// 	useRequest(() => dashboardShareApi.getShareHashDataSavedTitle(jobfithash));
	// const trackingSavedTitles = useMemo(
	// 	() => _trackingSavedTitles?.data || [],
	// 	[_trackingSavedTitles]
	// );

	// const { data: _userPrompts, loading: loadingUserPrompts } = useRequest(() =>
	// 	dashboardShareApi.getShareHashDataUserPrompt(jobfithash)
	// );
	// const userPrompts = useMemo(
	// 	() => _userPrompts?.data?.rows || [],
	// 	[_userPrompts]
	// );

	// console.log(`userPrompts`);
	// console.log(userPrompts);

	// console.log(`trackingJobStatus`);
	// console.log(trackingJobStatus);

	// console.log(`trackingTotalData`);
	// console.log(trackingTotalData);

	useEffect(() => {
		if (
			jobData?.configuration &&
			Object.keys(jobData?.configuration).length > 0
		) {
			for (const [key, value] of Object.entries(jobData.configuration)) {
				if (value?.all === true) {
					switch (key) {
						case 'job':
							getJobDetail();
							setJobInfoTab('jobDescription');
							break;

						case 'resume':
							getResumeData();
							setUserInfoTab('resume');
							break;

						case 'coverLetter':
							getCoverLetterData();
							//TODO: This is to set the default tab to resume. Need to update the logic
							if (userInfoTab !== 'coverLetter') {
								setUserInfoTab('coverLetter');
							}
							break;
						// Add additional cases as needed
						default:
							console.warn(`User data not shared: ${key}`);
							break;
					}
				}
			}
		}
	}, [jobData]);

	return (
		<>
			{ready && (
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Paper
								elevation={3}
								sx={{
									p: 2,
									display: 'flex',
									flexDirection: 'column',
									height: '85vh',
									overflow: 'auto',
								}}
							>
								<Tabs
									value={userInfoTab}
									onChange={handleUserTabChange}
									aria-label="Job Seeker's Information Tabs"
								>
									{jobData?.configuration?.resume?.all && (
										<Tab value="resume" label="Resume" />
									)}
									{jobData?.configuration?.coverLetter?.all && (
										<Tab value="coverLetter" label="Cover Letter" />
									)}
								</Tabs>
								{jobData?.configuration?.resume?.all && (
									<TabPanel value="resume" currentValue={userInfoTab}>
										<ResumeComponent resumeData={resumeData} />
									</TabPanel>
								)}
								{jobData?.configuration?.coverLetter?.all && (
									<TabPanel value="coverLetter" currentValue={userInfoTab}>
										<CoverLetterComponent coverLetterData={coverLetterData} />
									</TabPanel>
								)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper
								elevation={0}
								sx={{
									p: 2,
									display: 'flex',
									flexDirection: 'column',
									height: '85vh',
									overflow: 'auto',
								}}
							>
								<Tabs
									value={jobInfoTab}
									onChange={handleJobTabChange}
									aria-label="Job Information Tabs"
								>
									{/* <Tab label="Job Post" /> */}
									<Tab value="jobDescription" label="Job Description" />
									{/* <Tab label="User Dashboard" /> */}
								</Tabs>
								{/* <TabPanel value={jobInfoTab} index={0}>
									<JobPost
										applyUrl={jobData?.job?.applyUrl}
										jobData={jobData?.job}
									/>
								</TabPanel> */}
								<TabPanel
									value="jobDescription"
									currentValue={jobInfoTab}
									index={0}
								>
									<JobDescriptionComponent jobData={jobDetail} />
								</TabPanel>
								{/* <TabPanel value={jobInfoTab} index={1}>
									<JobDashboardComponent
										user={jobData?.user}
										certifications={resumeData?.certifications}
										trackingTotalData={trackingTotalData}
										loadingTrackingTotal={loadingTrackingTotal}
										trackingJobStatus={trackingJobStatus}
										trackingSavedTitles={trackingSavedTitles}
										userPrompts={userPrompts}
									/>
								</TabPanel> */}
							</Paper>
						</Grid>
					</Grid>
				</Box>
			)}
			<BackdropComponent />
		</>
	);
}

export default JobFitInsight;
