import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import jobApi from 'src/api/job.api';
import { useMemo } from 'react';
import Keyword from './Keyword';
import Divider from '@mui/material/Divider';
import ContactInfo from './ContactInfo';
import Summary from './Summary';
import Experience from './Experience';
import Education from './Education';
import Projects from './Projects';
import Certifications from './Certifications';
import Skills from './Skills';
import { CircularProgress, Box } from '@mui/material';

function ResumeComponent({ resumeData }) {
	return (
		<>
			<Keyword resumeId={resumeData?.id} jobData={resumeData?.jobData} />
			<Divider />
			<ContactInfo profile={resumeData?.profile} />
			<Summary summary={resumeData?.summary} />
			<Experience experiences={resumeData?.experiences} />
			<Education educations={resumeData?.educations} />
			<Projects projects={resumeData?.projects} />
			<Certifications certifications={resumeData?.certifications} />
			<Skills skills={resumeData?.skills} />
		</>
	);
}

export default ResumeComponent;
