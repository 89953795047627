import ApiFactory from 'src/api/ApiFactory';
import dayjs from 'dayjs';

const api = {
	getShareHashData(hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/sharableLink/hash/${hash}`);
	},
	getShareHashDataResume(jobId, hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/${jobId}/resume/share/${hash}`);
	},
	getShareHashDataCoverletter(coverLetterId, hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/coverLetter/${coverLetterId}/share/${hash}`);
	},
	getShareHashDataJobDetail(jobId, hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/${jobId}/share/${hash}`);
	},
	getShareHashDataTrackingTotal(hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/status/tracking/total/share/${hash}`);
	},
	getShareHashDataUserPrompt(hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/prompt/user/share/${hash}?includes=prompt`);
	},
	getShareHashDataSavedTitle(hash) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(`/job/title/tracking/share/${hash}`);
	},
	getShareHashDataJobStatus(
		hash,
		startDate = dayjs().subtract(1, 'y').format('YYYY-MM-DD'),
		endDate = dayjs().format('YYYY-MM-DD')
	) {
		const apiInstance = ApiFactory.create();
		return apiInstance.get(
			`/job/status/tracking/share/${hash}?startDate=${startDate}&endDate=${endDate}`
		);
	},
};
export default api;
