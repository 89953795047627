import Keyword from './Keyword';
import { Box } from '@mui/material';

function fixIssueLineEditor(str = '') {
	str = str.replace(new RegExp('<p><br></p>' + '$'), '');
	return str;
}

function CoverLetterComponent({ coverLetterData }) {
	return (
		<>
			<Keyword
				coverLetterId={coverLetterData?.id}
				jobData={coverLetterData?.job}
			/>
			<Box
				component="div"
				dangerouslySetInnerHTML={{
					__html: fixIssueLineEditor(coverLetterData?.description),
				}}
				sx={{
					wordWrap: 'break-word',
					// boxShadow: 2,
					border: 1,
					borderColor: '#f0f0f0',
					p: 2,
				}}
			/>
		</>
	);
}

export default CoverLetterComponent;
